import styles from "../css/aboutus.module.css";
import { Zoom } from "react-awesome-reveal";

function Aboutus() {
  return (
    <Zoom className={styles.aboutus}>
      <div id="about">
        <br />
        <br />
        <br />
        <h2 className={styles.abouttitle}>About Us</h2>
        <p className={styles.abouttext}>
          Through Nationals, Antaragni seeks to reach out to more colleges
          across different cities of the country. In a particular city, a
          college becomes the venue partner after entering into collaboration
          with the fest. A plethora of events including Jitterbug (Western Group
          Dance), Estampie (Solo Dance), Street Play, Stage Play, Antaragni
          Idol, Fine Arts Quizzes and Literary Events are conducted and these
          witness participation from over 50 colleges with a footfall of more
          than 2500 in every city. The events get media coverage by local media
          partners and the endorsement is done through branding banners,
          customized standees, flyers and stalls in the road trip cities. This
          year, Antaragni is taking its road trip to Delhi, Indore, Chandigarh,
          Hyederabad, Jaipur, Pune and Lucknow. With an extremely successful
          last edition, we are expecting an even grander one this year, which
          hopefully will surpass all previous benchmarks.
        </p>
      </div>
    </Zoom>
  );
}
export default Aboutus;
