import { Fade } from "react-awesome-reveal";
import { useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { VerticalTimeline } from "react-vertical-timeline-component";
import styles from "../css/cities.module.css";
import "../css/cities.css";
import CitiesCard from "./citiesCard";
import galleryImg from "../assets/antaragni.jpg";

const citiesDB = [
  {
    id: 1,
    img: galleryImg,
    city: "Kolkata",
    date: "TBA",
    result: "TBA",
  },
  {
    id: 2,
    img: galleryImg,
    city: "Shillong",
    date: "TBA",
    result: "TBA",
  },
  {
    id: 3,
    img: galleryImg,
    city: "Imphal",
    date: "TBA",
    result: "TBA",
  },
  {
    id: 4,
    img: galleryImg,
    city: "Bangalore",
    date: "TBA",
    result: "TBA",
  },
  {
    id: 5,
    img: galleryImg,
    city: "Mumbai",
    date: "TBA",
    result: "TBA",
  },
  {
    id: 6,
    img: galleryImg,
    city: "Hyderabad",
    date: "TBA",
    result: "TBA",
  },
];

function Cities({ data }) {
  const [citiesData] = useState(citiesDB);
  return (
    <div className={styles.schedule} id="cities">
      <br />
      <br />
      <Fade bottom>
        <h2 className={styles.citiestitle}>Cities</h2>
      </Fade>
      <div className={styles.cardst}>
        {data.length <= 0 ? (
          <h1
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            To be announced...
          </h1>
        ) : (
          <VerticalTimeline>
            {data.map((event) => (
              <CitiesCard event={event} />
            ))}
          </VerticalTimeline>
        )}
      </div>
    </div>
  );
}

export default Cities;
