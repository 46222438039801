import styles from "../css/canvas.module.css";
import "../css/canvas.css";
import Typewriter from "typewriter-effect";

function Home() {
  return (
    <div className={styles.canvas} id="home">
      <div className={styles.content}>
        <h2>Nationals'24</h2>
        <h2>Nationals'24</h2>
      </div>
      <div className={styles.typewriterContainer}>
        {/* <h1 className={styles.nationals}>Nationals'24</h1> */}
        <h1 className={styles.typewriter}>
          <Typewriter
            options={{
              strings: [
                '<span class=typed1 style="font-family:Typewriter"> Estampie</span>',
                '<span class=typed2 style="font-family:Typewriter"> Jitterbug</span>',
                '<span class=typed3 style="font-family:Typewriter"> Kavyanjali</span>',
                '<span class=typed2 style="font-family:Typewriter"> Monologue</span>',
              ],
              autoStart: true,
              loop: true,
              typeSpeed: 100,
              cursor: ".",
              // color: "green",
              cursorClassName: styles.typewriterCursor,
              wrapperClassName: styles.typewriterWrapper,
            }}
          />
        </h1>
      </div>
    </div>
  );
}

export default Home;
