// import styles from "../css/contacts.module.css";
// import ContactCard from "./contactcard";

// function Contacts() {
//   return (
//     <div className={styles.contacts}>
//       <h2 className={styles.contacttitle}>Contacts</h2>
//       <div className={styles.contactcards}>
//         <ContactCard />
//         <ContactCard />
// <ContactCard />
//       </div>
//     </div>
//   );
// }

// export default Contacts;

// import { useState, useEffect } from "react";
import "../css/contacts.css";
import ContactCard from "./contactcard";
// import { getSingleDoc } from "../config/firebaseFirestore";

const Contacts = ({ data }) => {
  // const [contactData, setContactData] = useState([]);

  // const getContactData = async () => {
  //   let data = await getSingleDoc("WebContents", "Junoon23");
  //   if (data != null) {
  //     const dataArray = data.data;
  //     const contactArray = [];
  //     for (let i = 0; i < dataArray.length; i++) {
  //       if (dataArray[i].flag === "contactInfo") {
  //         contactArray.push(dataArray[i]);
  //       }
  //     }
  //     setContactData(contactArray);
  //   }
  // };

  // useEffect(() => {
  //   getContactData();
  // }, []);

  return (
    <div className="contact-us-page" id="contacts">
      <div>
        <br />
        <br />
        <h1 className="page-title">Contact Us</h1>
      </div>
      <br />
      <br />
      <br />
      {data.length <= 0 ? (
        <h1 style={{ color: "white", textAlign: "center" }}>
          To be announced...
        </h1>
      ) : (
        <div className="contactCard-container">
          {data.map((contact) => {
            return (
              <ContactCard
                img={contact.img.content.url}
                name={contact.name.content}
                mobile={contact.text1.content}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Contacts;
