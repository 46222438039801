import styles from "../css/card.module.css";

function LocationCard() {
  return (
    <article class={styles.card}>
      <img
        class={styles.cardbackground}
        src="https://i.imgur.com/QYWAcXk.jpeg"
        alt="picc"
        width="1920"
        height="2193"
      />
      <div class={styles.cardcontent1}>
        <div class={styles.cardcontent2}>
          <h2 class={styles.cardtitle}>Mumbai</h2>
          <p class={styles.carddesc}>
            Winner: Lorem Ipsum
            <br />
            Date: Jan 10,2023
          </p>
        </div>
      </div>
    </article>
  );
}

export default LocationCard;
