import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { Zoom, Slide } from "react-awesome-reveal";
import "react-vertical-timeline-component/style.min.css";
import "../css/citiesCard.css";

const CitiesCard = ({ event }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "rgba(0, 0, 0, 0)",
        color: "#fff",
        border: "0",
      }}
      date={event.name.content}
      icon={
        <Zoom duration="700" className="timeline-icon">
          <img src={event.img.content.url} alt="city" className="timeline-icon" />
        </Zoom>
      }
    >
      <Slide>
        <div className="myCard">
          {/* <a href={event.link} target="_blank" rel="noreferrer"> */}
          <div className="innerCard">
            <div className="frontSide">
              <img src={event.img.content.url} alt="city" className="timeline-img" />
            </div>

            <div className="backSide">
              <p className="title">{event.name.content}</p>   
              <p id="card-data">{event.text1.content}</p>
              <p id="card-data">{event.text2.content}</p>
              <p id="card-data">
                <a href={event.link.content} target="_blank" rel="noreferrer">
                  Form Link
                </a>
              </p>
            </div>
          </div>
        </div>
      </Slide>
    </VerticalTimelineElement>
  );
};

export default CitiesCard;
