import styles from "../css/navbar.module.css";

function Navbar() {
  return (
    <header className={styles.mainbar}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles.title} onClick={() => window.location.reload()}>
          <h2 className="navbar-left">NATIONALS</h2>
        </div>
      </div>

      <nav className={styles.links}>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#cities">Cities</a>
        <a href="#events">Events</a>
        {/* <a href="#gallery">Gallery</a> */}
        <a href="#contacts">Contacts</a>
      </nav>
    </header>
  );
}

export default Navbar;
