import React from "react";
import styles from "./css/app.module.css";
import {
  Navbar,
  Aboutus,
  Cities,
  Events,
  Gallery,
  Contacts,
  Home,
  Stars,
} from "./components/index";
// import ParticlesComp from "./components/particles";
import { useState, useEffect } from "react";
import { getDoc, getFirestore, doc } from "firebase/firestore";
import firebaseApp from "./config/firebaseConfig";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [contacts, setContacts] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, "WebContentsNew", "nationals");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let contacts = [];
      let schedule = [];
      let sponsors = [];
      
      for (let i = 0; i < rawData.data.length; i++) {
        // console.log(rawData.data[i].flag.content, rawData.data[i].flag)
        switch (rawData.data[i].flag.content) {
          case "contact":
            contacts.push(rawData.data[i]);
            break;
          case "sponsor":
            sponsors.push(rawData.data[i]);
            break;
          case "schedule":
            schedule.push(rawData.data[i]);
            break;
        }
      }
      setContacts(contacts);
      setSchedule(schedule);
      setSponsors(sponsors);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={styles.app}>
      {/* <ParticlesComp /> */}
      <Navbar />
      <Stars />
      <Home />
      <Aboutus />
      <Cities data={schedule} />
      <Events />
      {/* <Gallery /> */}
      <Contacts data={contacts} />
    </div>
  );
};

export default App;
