import React from "react";
import "../css/contactcard.css";
import profile from "../assets/profile.png";
import email from "../assets/email.png";
import telephone from "../assets/telephone.png";

const ContactCard = ({ img, name, mobile }) => {
  return (
    <div className="contact-cardm">
      <div className="contact-card">
        <img className="profile-img" src={img} alt="contact" />
      </div>

      <div className="contact-card2">
        <div className="contact-upper">
          <p className="contact-name">{name}</p>
        </div>
        <div className="contact-lower">
          <div className="contact">
            <img className="contact-icon" src={email} alt="email-icon" />

            <p className="contact-info">nationals@antaragni.in</p>
          </div>
          <div className="contact">
            <img
              className="contact-icon"
              src={telephone}
              alt="telephone-icon"
            />

            <p className="contact-info">{mobile}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
