import styles from "../css/events.module.css";
import { Slide } from "react-awesome-reveal";

function Events() {
  return (
    <div className={styles.events} id="events">
      <div className={styles.maintitle}>Events</div>
      <div className={styles.eventsList}>
        <Slide className={styles.event}>
          <div>
            <h2 className={styles.eventstitle}>ESTAMPIE</h2>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  No accommodation(in Delhi) will be provided by the organising
                  team during nationals
                </li>
              </ul>
              <br></br>
            </p>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>
                  Dance styles can vary from western Hip ­ Hop and the typical
                  free style Bollywood style to Folk style.
                </li>
                <li>Time limit : 3 minutes(length of music track)</li>
                <li>Props are encouraged.</li>
                <li>No participant is allowed to spoil the stage.</li>
                <li>
                  Participants must bring college id proofs for verification
                </li>
                <li>
                  Participants have to report on registration desk 30 minutes
                  before the start of the event.
                </li>
                <li>
                  The following may lead to negative marking/disqualification­:
                  <ul>
                    <li>
                      Any misbehaviour with other teams , organizing team,
                      judges
                    </li>
                    <li>Deviation from rules</li>
                    <li>Late arrival without a valid reason</li>
                  </ul>
                </li>
              </ol>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Judging Criteria:</h3>
            <p className={styles.eventstext}>
              Choreography, expressions, energy level, stage utilization,
              costumes and props, overall impact, innovation and presentation.
            </p>
          </div>
        </Slide>
        <Slide className={styles.event} direction="right">
          <div>
            <h2 className={styles.eventstitle}>Monologue</h2>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  No accommodation will be provided by the organising team
                  during nationals.
                </li>
              </ul>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>Language used can be Hindi or English or bilingual.</li>
                <li>
                  Script should be original/self-written and should not be
                  copied. If found guilty, would result in disqualification of
                  the team.
                </li>
                <li>Vulgarity is strictly prohibited.</li>
                <li>In any case the judge's decision will be final.</li>
                <li>Time Limit: 6 to 10 minutes</li>
                <li>Contestants are allowed one handheld prop.</li>
                <li>No team is allowed to spoil the stage</li>
                <li>
                  Participants must bring college id proofs for verification.
                </li>
                <li>
                  Participants have to report on the registration desk 30
                  minutes before the start of the event.
                </li>
                <li>
                  The following may lead to negative marking/disqualification­:
                  <ul>
                    <li>
                      Any misbehaviour with other teams , organizing team,
                      judges
                    </li>
                    <li>Deviation from rules</li>
                    <li>Late arrival without a valid reason</li>
                  </ul>
                </li>
                <li>The competition will consist of one final round.</li>
              </ol>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Judging Criteria:</h3>
            <p className={styles.eventstext}>
              Contestants will be judged on the basis of the ability of the
              character, use of voice, use of body, stage movement, and
              gestures.
            </p>
          </div>
        </Slide>
        <Slide className={styles.event}>
          <div>
            <h2 className={styles.eventstitle}>JITTERBUG </h2>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  No accommodation will be provided by the organising team
                  during nationals.
                </li>
              </ul>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>
                  The participating teams are allowed to have 6-20 members in
                  their teams. All the members of the team should be present at
                  the time of the performance.
                </li>
                <li>
                  All the members of the team should belong to the same
                  Institute/University. There can be more than 1 team from the
                  same Institute/University.
                </li>
                <li>Time limit : 5-9 minutes(length of music track).</li>
                <li>
                  Live music is allowed (eg­ tapping , shouting , lipsing).
                </li>
                <li>Props are allowed.</li>
                <li>No team is allowed to spoil the stage</li>
                <li>
                  Teams are suggested to bring 2 copies of their dance track,
                  preferably in a pen­drive.
                </li>
                <li>
                  Team members must bring college id proofs for verification ,
                  team heads will be the only one carrying all the id's of the
                  team members on registration desk
                </li>
                <li>
                  Teams have to report on registration desk 30 minutes before
                  the start of the event.
                </li>
                <li>
                  The following may lead to negative marking/disqualification­:
                  <ul>
                    <li>
                      Any misbehaviour with other teams , organizing team,
                      judges
                    </li>
                    <li>Deviation from rules</li>
                    <li>Late arrival without a valid reason</li>
                    <li>Deviation from Dance Style</li>
                  </ul>
                </li>
              </ol>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Judging Criteria:</h3>
            <p className={styles.eventstext}>
              Synchronization, choreography, expressions, energy level, stunts,
              stage utilization, costumes, overall impact, innovation and
              presentation.
            </p>
          </div>
        </Slide>
        <Slide className={styles.event} direction="right">
          <div>
            <h2 className={styles.eventstitle}>Antaragni Idol</h2>
            <p className={styles.eventstext}>
              Introduction​: Antaragni Idol, the most popular of the musical
              competitions of Antaragni, continues to elevate its level after
              taking a giant leap last year. Be it a sweet mesmerizing ghazal or
              a scintillating bright qawwali; be it folk or filmi; you can
              showcase your song on our platform. So, unleash the singer within
              you. Come and gauge your talent with some of the gifted children
              of mother India.
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>No accommodation will be provided by organising team.</li>
                <li>
                  The participants have to sing a song of their own choice.
                </li>
                <li>Time limit: 3 minutes (excluding sound check).</li>
                <li>
                  Team limit : 1 participant (if 2 participants in a team,
                  Register individually and your score will be your average)
                </li>
                <li>
                  If participant wishes to use karaoke, the track of it must be
                  handed over to the coordinators beforehand in a disk or pen
                  drive.
                </li>
                <li>No backing vocals are allowed.</li>
                <li>
                  The decision of the judges and the coordinators shall be final
                  and binding in all cases.
                </li>
              </ol>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Judging Criteria:</h3>
            <p className={styles.eventstext}>
              Judgment is based on the voice quality and the vocal abilities of
              the singer. No consideration shall be given to karaoke.
            </p>
          </div>
        </Slide>
        <Slide className={styles.event}>
          <div>
            <h2 className={styles.eventstitle}>Quiz</h2>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  No accommodation will be provided by the organising team
                  during nationals.
                </li>
              </ul>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>
                  Participation in the quizzes is restricted to teams of 3 or
                  fewer people. Cross-college teams are allowed.
                </li>
                <li>
                  The Quiz consists of a written elimination round followed by
                  on-stage finals for 5 qualifying teams.
                </li>
                <li>
                  The finals are expected to consist of the usual rounds:
                  infinite bounce, Audio Visuals, Connect, etc. The actual
                  structure will be decided by the quizmasters.
                </li>
                <li>
                  Use of any unfair means is prohibited and shall lead to
                  immediate disqualification.
                </li>
                <li>
                  The decision of the Quizmasters/Organizers shall be final and
                  binding. Nothing personal but we need to run on a tight
                  schedule.
                </li>
                <li>No team is allowed to spoil the stage.</li>
                <li>
                  Participants must bring college id proofs for verification.
                </li>
                <li>
                  Participants have to report on the registration desk 30
                  minutes before the start of the event.
                </li>
                <li>
                  The following may lead to negative marking/disqualification­:
                  <ul>
                    <li>
                      Any misbehaviour with other teams , organizing team,
                      judges
                    </li>
                    <li>Deviation from rules</li>
                    <li>Late arrival without a valid reason</li>
                  </ul>
                </li>
              </ol>
            </p>
          </div>
        </Slide>
        <Slide className={styles.event} direction="right">
          <div>
            <h2 className={styles.eventstitle}>Modern Art</h2>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>Theme will be given on the spot.</li>
                <li>You will get 1 A3 sheet each.</li>
                <li>
                  You have to bring their own colors. ( Any medium is allowed
                  for example, water color, crayons, color pencils etc.)
                </li>
                <li>Time Limit: 2 hours</li>
              </ul>
            </p>
          </div>
        </Slide>
        <Slide className={styles.event}>
          <div>
            <h2 className={styles.eventstitle}>Poetry Slam</h2>
            <p className={styles.eventstext}>
              It’s not just what you write; it’s also important how you use it
              to capture the audience’s imagination. Aspiring poets, try your
              hand out at the competitive art of performance poetry.
            </p>
            <br />
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>
                  Any length and style of poetry is accepted (for example -
                  blank verse, rhyme, sonnet or abstract).
                </li>
                <li>
                  Poem must be an original piece of work - plagiarism of any
                  sort if detected will result in immediate disqualification.
                </li>
                <li>
                  Time limit is four​ minutes. After three minutes, there is a
                  10-second grace period after which penalty will be imposed.
                </li>
                <li>
                  Use of props, special costumes, musical instruments or
                  pre-recorded music is not allowed.
                </li>
                <li>
                  The top three performances shall be adjudged the winners of
                  Poetry Slam.
                </li>
                <li>
                  There is no upper limit of participants from a particular
                  college. The judging shall however be done individually.
                </li>
                <li>Judges’ decision is final and binding.</li>
                <li>
                  The organizing team reserves the right to change or modify the
                  rules.
                </li>
              </ol>
            </p>
          </div>
        </Slide>
        <Slide className={styles.event} direction="right">
          <div>
            <h2 className={styles.eventstitle}>Kavyanjali</h2>
            <p className={styles.eventstext}>
              It is a platform where the participants will have to recite some
              of their best poems. This is the time when you can showcase your
              hidden poetic skills. Participants have to narrate their genuine
              poem(s) only.
            </p>
            <br />
            <br />
            <h3 className={styles.eventssubtitle}>Rules and Regulations:</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  Any length and style of poetry is accepted (for example -
                  blank verse, rhyme, sonnet or abstract).
                </li>
                <li>
                  The maximum time given to one participant to perform will be
                  3-5 minutes in which he/she can recite as many poems as
                  possible.
                </li>
                <li>
                  If a team of 2 is participating, register individually.
                  Scoring will be the average of your scores.
                </li>
                <li>
                  The participants must submit a hardcopy of their poems to the
                  judges on the venue.
                </li>
                <li>
                  The poems must be self ­written and strictly in Hindi. Any
                  translation from other languages will not be accepted.
                </li>
                <li>
                  Unfair means are not allowed. If anyone is found guilty for
                  copying, his/her Registration will be cancelled
                </li>
                <li>Negative marking in case of exceeding time limit.</li>
                <li>
                  The decision of the judges will be final. Judging will be
                  based on the idea and recitation of the poem.
                </li>
              </ul>
            </p>
          </div>
        </Slide>
        <Slide className={styles.event}>
          <div>
            <h2 className={styles.eventstitle}>Nukkad Natak</h2>
            <h3 className={styles.eventssubtitle}>General Guidelines</h3>
            <p className={styles.eventstext}>
              <ul>
                <li>
                  No accommodation will be provided by the organising team
                  during nationals.
                </li>
              </ul>
            </p>
            <br></br>
            <h3 className={styles.eventssubtitle}>Rules:</h3>
            <p className={styles.eventstext}>
              <ol>
                <li>
                  One team can have a maximum of 20 members (18 in the circle
                  with 2 rolling). Maximum 2 teams are allowed from one college.
                </li>
                <li>Language used can be Hindi or English or bilingual.</li>
                <li>
                  Script should be original/self-written and should not be
                  copied. If found guilty, it would result in disqualification
                  of the team.
                </li>
                <li>Vulgarity is strictly prohibited.</li>
                <li>Time limit: 30 minute</li>
                <li>Any electronic device or recorded music is not allowed</li>
                <li>
                  No team is allowed to spoil the stage. A penalty of 10% marks
                  will be imposed if any team spoils the stage.
                </li>
                <li>
                  Only household objects can be used as props (Dholak, Stick,
                  Drums, Chunni are allowed). Other props can be used with the
                  permission of event managers. Marks will be allocated based on
                  the creative use of props and not on their number.
                </li>
                <li>In any case the judge's decision will be final.</li>
              </ol>
            </p>
          </div>
        </Slide>
      </div>
    </div>
  );
}
export default Events;
